import styles from "./staffOneEditForm.module.scss";
import { Form } from "react-bootstrap";
import { Formik } from "formik";
import { observer } from "mobx-react-lite";
import { useParams } from "react-router-dom";
import { useStores } from "stores/index";
import React, { useState, useEffect } from "react";
import { format } from "date-fns";
import { getValidationSchema } from "shared/utils/validation/validationStaffForm";
import { cloneDeep, isEmpty, isEqual, without } from "lodash";

import NumberFormat from "react-number-format";
import StaffOneEditPhotoAlt from "./StaffOneEditFormPhotoAlt";

import StaffOneEditPhone from "./StaffOneEditFormPhone";
import StaffOneEditEmail from "./StaffOneEditFormEmail";
import StaffOneEditCompanies from "./StaffOneEditFormCompanies";
import StaffOneEditFormWorker from "./StaffOneEditFormWorker";

import { Input } from "shared/ui/Inputs/Input";
import AutoComplete from "shared/ui/Inputs/AutoComplete";
import DatePickerField from "shared/ui/Inputs/DatePickerField";
import Select from "shared/ui/Inputs/Select";
import Switch from "shared/ui/Inputs/Switch";
import ButtonsGroupForFormEdit from "shared/ui/ButtonsGroup/ButtonsGroupForFormEdit";
import Textarea from "shared/ui/Inputs/Textarea";
import ErrorMessage from "shared/ui/ErrorMessage";
import LoadedComponent from "widgets/LoadedComponent";
import icon1c from "shared/assets/images/mainIcons/icon1C.svg";
import { ReactComponent as IconCircle } from "shared/assets/images/iconStatus/CircleCheck.svg";
import {
  ICompany,
  IRecordsForEdit
} from "stores/StaffModule/types/RecordsForEdit";
import StaffOneEditFormWindow from "./StaffOneEditFormWindow";
import SwitchedTabs from "shared/ui/Tabs/SwitchedTabs";
import StatusIcon from "shared/ui/StatusIcon";
import { ErrorBoundary } from "react-error-boundary";
import ErrorFallback from "widgets/LoadedComponent/Error/ErrorFallback";
import { ViewField } from "shared/ui/ViewField";
import { calculateYears } from "shared/utils/helpers/calculateYears";
import { calculateDays } from "shared/utils/helpers/calculateDays";
import { getParsedDate } from "shared/utils/helpers/getParsedDate";
import FormikReload from "./FormikReload";
import { diff } from "deep-object-diff";
import { getEntries } from "shared/utils/helpers/getEntries";
import { getKeys } from "shared/utils/helpers/getKeys";
import { getValues } from "shared/utils/helpers/getValues";
import { classNames } from "shared/utils/helpers/classNames";

type StaffOneEditFormProps = {
  data: Partial<IRecordsForEdit>;
  previousValue: Partial<IRecordsForEdit>;
  cols: string[];
  setSelectedItem: (arg: string) => void;
};

type ValuesType = Omit<
  Partial<IRecordsForEdit>,
  | "company"
  | "phone"
  | "photo_alt"
  | "photo"
  | "position"
  | "building"
  | "event_type"
>;

const StaffOneEditForm = ({
  data,
  previousValue,
  cols,
  setSelectedItem
}: StaffOneEditFormProps) => {
  const { staffOneEditStore, staffDaDataStore } = useStores();

  const { isLoadingAutoData } = staffDaDataStore;

  const conditionOfPpe = ["size", "height", "shoe_size"];
  const [openedListName, setOpenedListName] = useState("");
  const [selectedWorkerClothes, setSelectedWorkerClothes] = useState("none");
  const [isLoadingField, setIsLoadingField] = useState("");
  const [requiredFields, setRequiredFields] = useState<string[]>([]);
  const [openWindow, setOpenWindow] = useState(false);
  const [selectedKey, setSelectedKey] = useState("");
  const [selectedCompany, setSelectedCompany] = useState("");

  const { id, edit } = useParams();

  useEffect(() => {
    setSelectedCompany(staffOneEditStore.selectedCompanyId[id]);
  }, []);

  useEffect(() => {
    isLoadingField !== staffOneEditStore.isLoadingField &&
      setIsLoadingField(staffOneEditStore.isLoadingField);
  }, [staffOneEditStore.isLoadingField]);

  useEffect(() => {
    setOpenedListName(null);
  }, [cols]);

  const changeOpenedWindows = (name: string) => {
    openedListName === name ? setOpenedListName("") : setOpenedListName(name);
  };

  const add1CIcon = (field_name: string) => {
    if (staffOneEditStore.historyData[id]) {
      for (const group of getValues(staffOneEditStore.historyData[id])) {
        const historyItems = getValues(getValues(group)[0]);
        for (const item of getValues(historyItems)) {
          if (item["action"] === field_name) {
            return item["user_name"] === "crm_bot";
          }
        }
      }
      return false;
    }
  };

  const getIconBlock = (key: string) => {
    const handleOnClickEditIcon = () => {
      setSelectedKey(selectedKey === key ? "" : key);
      setOpenWindow(!openWindow);
    };
    return (
      <div className={styles.iconWrapper}>
        {add1CIcon(key) ? <img src={icon1c} /> : null}
        {staffOneEditStore.objectVersionColumns[key]?.["withHistory"] ? (
          <div
            className={styles.editIcon}
            id={`StaffOneEditForm_edit_${key}`}
            onClick={handleOnClickEditIcon}
          >
            <StatusIcon
              icon="iconedit"
              color={selectedKey === key ? "blue-lazure" : "bw-gray5"}
            />
          </div>
        ) : null}
      </div>
    );
  };

  const getModalWindow = (key: string, title: string) => {
    const handleSetOpenWindow = () => {
      setOpenWindow(false);
      setSelectedKey("");
    };

    return staffOneEditStore.objectVersionColumns[key]?.["withHistory"] ? (
      <ErrorBoundary FallbackComponent={ErrorFallback}>
        <StaffOneEditFormWindow
          show={openWindow && selectedKey === key}
          field={key}
          title={title}
          setOpenWindow={handleSetOpenWindow}
        />
      </ErrorBoundary>
    ) : null;
  };

  const getClassNameForOVColumns = (key: string) => {
    const isOV = staffOneEditStore.objectVersionColumns[key]?.["withHistory"];
    const isOVOrCrm = add1CIcon(key) || isOV;
    const isOVAndCrm = add1CIcon(key) && isOV;
    return classNames(styles.wrapper, {
      [styles.inputWithIcon]: isOVOrCrm,
      [styles.inputWithDoubleIcon]: isOVAndCrm,
      [styles.activeBlock]: key === selectedKey,
      [styles.objectVersionBlock]: isOV
    });
  };

  const deleteDismissFields = (data: Partial<IRecordsForEdit>) => {
    const newData = cloneDeep(data);
    getKeys(newData).forEach(
      (key) => key.includes("dismiss") && delete newData[key]
    );
    return newData;
  };
  const handleChangeActiveTab = (company: string) => {
    if (company !== selectedCompany) {
      setSelectedCompany(company);
      staffOneEditStore.setLastStatus(id, company);
    }
  };
  const updateOVCols = (values: ValuesType) => {
    getKeys(staffOneEditStore.objectVersionColumns).forEach(async (key) => {
      // Проверяем, что поле OV редактируется в обычной форме редактирования, а не в отдельном окне с историей
      const withoutHistory =
        !staffOneEditStore.objectVersionColumns[key]?.["withHistory"];
      // Проверяем, что поле было изменено (отличается от initialValues)
      const isEdited = data[key] !== values[key];
      // Проверяем, что можем редактировать поле, исходя из правил выше
      const canEditOVCol = isEdited && withoutHistory;

      if (canEditOVCol) {
        // если поле очищено (null или ""), то вызываем метод deleteOVData
        if (!values[key]) {
          await staffOneEditStore.deleteOVData(
            id,
            staffOneEditStore.selectedOneForEdit["company"]?.[
              staffOneEditStore.selectedCompanyId[id]
            ]?.["employments"]?.[staffOneEditStore.selectedTypeOfJob[id]]?.[
              key
            ]?.["id"]
          );
        }
        // если поле было в initialValues и не очищено, значит оно изменено и вызываем метод updateOVData
        else if (data[key]) {
          await staffOneEditStore.updateOVData(
            id,
            staffOneEditStore.selectedOneForEdit["company"]?.[
              staffOneEditStore.selectedCompanyId[id]
            ]?.["employments"]?.[staffOneEditStore.selectedTypeOfJob[id]]?.[
              key
            ]?.["id"],
            values[key] as string
          );
        }
        // в остальном (поля не было в initialValues) вызываем метод addOVData
        else {
          const staffGuid: string = getValues(
            staffOneEditStore.selectedOneForEdit["company"][
              staffOneEditStore.selectedCompanyId[id]
            ]?.["employments"]?.[staffOneEditStore.selectedTypeOfJob[id]]?.[key]
          )[0]?.["staff_guid"];
          await staffOneEditStore.addOVData(
            id,
            key,
            staffGuid || "",
            format(new Date(), "yyyy-MM-dd"),
            values[key]
          );
        }
      }
    });
  };
  const handleSubmit = async (values: ValuesType) => {
    updateOVCols(values);

    const valuesByStaffCard = getEntries(values).filter(
      (item) => !staffOneEditStore.objectVersionColumns[item[0]]
    );
    const initialValuesByStaffCard = getEntries(
      edit === dismiss && staffOneEditStore.lastStatus !== dismissStatus
        ? deleteDismissFields(data)
        : data
    ).filter((item) => !staffOneEditStore.objectVersionColumns[item[0]]);

    const editedValues = diff(
      Object.fromEntries(initialValuesByStaffCard),
      Object.fromEntries(valuesByStaffCard)
    ) as Record<string, string | number | string[]>;
    const companiesDiff = diff(
      staffOneEditStore.initialCompanyList[id],
      values["company"]
    );

    if (!isEmpty(companiesDiff)) {
      editedValues.company = values["company"];
    }

    if (!isEmpty(editedValues)) {
      await staffOneEditStore.updateField(
        staffOneEditStore.selectedOneForEdit["id"],
        editedValues
      );
    }
  };

  // hardcode: id группы Покинул ПОЛАТИ и статуса Уволен для Покинул ПОЛАТИ
  const dismiss = "cc22b21ce8eff886c3564893bdd350fdb61b9431";
  const dismissStatus = "e329ed9b615763361b86d0d31cc3aac6b620c262";

  const initialValues =
    edit === dismiss && staffOneEditStore.lastStatus !== dismissStatus
      ? deleteDismissFields(data)
      : data;

  return (
    <>
      {getKeys(data).length && cols.length ? (
        <div className={edit === dismiss ? styles.withTabs : ""}>
          {edit === dismiss ? (
            <SwitchedTabs
              tabs={
                getValues(staffOneEditStore.selectedOneForEdit.company) as {
                  id: string;
                  title: string;
                }[]
              }
              selectedTab={selectedCompany}
              changeActiveTab={handleChangeActiveTab}
            />
          ) : null}
          <div
            className={classNames(styles.flex, {
              [styles.ppeContainer]:
                getKeys(data).filter((dt) =>
                  conditionOfPpe.find((el) => el === dt)
                ).length >= 1
            })}
          >
            <Formik
              initialValues={initialValues}
              validateOnBlur
              onSubmit={handleSubmit}
              validationSchema={getValidationSchema(cols, requiredFields)}
              enableReinitialize
            >
              {({
                values,
                initialValues,
                handleChange,
                handleBlur,
                isValid,
                handleSubmit,
                handleReset,
                dirty,
                setFieldValue,
                setFieldTouched,
                errors
              }) => {
                const onlyPhoto =
                  ((values["photo"] || values["photo_alt"]) &&
                    Object.keys(values).length === 1) ||
                  (values["photo"] &&
                    values["photo_alt"] &&
                    Object.keys(values).length === 2);
                // булевое значение для отключения отображения ButtonsGroupForFormEdit у группы Контакты
                const onlyContacts =
                  // id группы редактирования - Контакты
                  edit === "6d46a48caee6b34d2be2f3cf6f31f23265ea784d" &&
                  // в полях группы только телефон и почта (для других полей кнопки будут нужны)
                  without(cols, "phone", "email").length === 0;

                return (
                  <>
                    <FormikReload
                      cols={cols}
                      data={data}
                      setRequiredFields={setRequiredFields}
                    />
                    <Form className={styles.form}>
                      <div className={styles.inputs}>
                        {!isEmpty(staffOneEditStore.errorsMessage[id]) &&
                        staffOneEditStore.errorsMessage[id]["from"] ? (
                          <div className={styles.error}>
                            <ErrorMessage
                              errors={
                                staffOneEditStore.errorsMessage[id].message
                              }
                            />
                          </div>
                        ) : null}
                        {Object.keys(values).map((key) => {
                          const colInfo = staffOneEditStore.staffTableCols[key];
                          const colParams =
                            staffOneEditStore.staffTableParams[key];
                          const disabled =
                            colParams?.dadata?.length > 0 ||
                            colParams?.editable !== "on" ||
                            // hardcode для Покинул ПОЛАТИ
                            [
                              "dismiss_date",
                              "dismiss_position",
                              "dismiss_object"
                            ].includes(key);
                          const checkIfFieldDisabled = (name: string) => {
                            return (
                              staffOneEditStore.staffTableParams[name].dadata
                                .length > 0 ||
                              staffOneEditStore.staffTableParams[name]
                                ?.editable !== "on"
                            );
                          };
                          const isOV =
                            staffOneEditStore.objectVersionColumns[key]?.[
                              "withHistory"
                            ];
                          const isOVOrCrm = add1CIcon(key) || isOV;
                          const isOVAndCrm = add1CIcon(key) && isOV;

                          if (!colParams?.access_show) return;

                        switch (key) {
                          case "photo":
                            return;
                          case "photo_alt": {
                            return (
                              <StaffOneEditPhotoAlt
                                key={key}
                                mainPhoto={values["photo"]}
                                value={values[key]}
                                disabled={disabled}
                                userId={
                                  staffOneEditStore.selectedOneForEdit["id"]
                                }
                              />
                            );
                          }
                          case "phone":
                            return (
                              <ErrorBoundary
                                FallbackComponent={ErrorFallback}
                                key={key}
                              >
                                <div className={styles.wrapper}>
                                  <StaffOneEditPhone disabled={disabled} />
                                </div>
                              </ErrorBoundary>
                            );
                          case "email":
                          case "email_confirm":
                            return (
                              <ErrorBoundary
                                FallbackComponent={ErrorFallback}
                                key={key}
                              >
                                <div
                                  className={`${styles.wrapper} ${
                                    add1CIcon(key) ? "" : styles.icon1C_email
                                  }`}
                                >
                                  <StaffOneEditEmail
                                    values={values}
                                    initialValues={initialValues}
                                    dirty={dirty}
                                    isValid={isValid}
                                    disabled={disabled}
                                  />
                                </div>
                              </ErrorBoundary>
                            );
                          case "birthday":
                            return (
                              <div className={styles.row} key={key}>
                                <DatePickerField
                                  name={key}
                                  title={colInfo["title"]}
                                  isCalendarOpened={openedListName === key}
                                  setIsCalendarOpened={() => {
                                    changeOpenedWindows(key);
                                  }}
                                  disabled={disabled}
                                  required={colParams.required}
                                />
                                <ViewField
                                  title="Возраст"
                                  value={
                                    values[key]
                                      ? calculateYears(
                                          getParsedDate(values[key]),
                                          new Date()
                                        ) < 16
                                        ? "Сотрудник должен быть старше 16 лет"
                                        : calculateYears(
                                            getParsedDate(values[key]),
                                            new Date()
                                          ) > 70
                                        ? "Сотрудник должен быть моложе 70 лет"
                                        : calculateYears(
                                            getParsedDate(values[key]),
                                            new Date()
                                          ).toString()
                                      : ""
                                  }
                                  disabled
                                />
                              </div>
                            );
                          case "training_date_start":
                            return (
                              <div
                                className={
                                  "training_date_finish" in values
                                    ? styles.row_three
                                    : ""
                                }
                                key={key}
                              >
                                <DatePickerField
                                  name={key}
                                  title={colInfo["title"]}
                                  placeholderVisible
                                  isCalendarOpened={openedListName === key}
                                  setIsCalendarOpened={() => {
                                    changeOpenedWindows(key);
                                  }}
                                  placement="right"
                                  disabled={disabled}
                                  required={colParams.required}
                                />
                                {"training_date_finish" in values ? (
                                  <>
                                    <DatePickerField
                                      name="training_date_finish"
                                      title={
                                        staffOneEditStore.staffTableCols[
                                          "training_date_finish"
                                        ]["title"]
                                      }
                                      isCalendarOpened={
                                        openedListName ===
                                        "training_date_finish"
                                      }
                                      setIsCalendarOpened={() => {
                                        changeOpenedWindows(
                                          "training_date_finish"
                                        );
                                      }}
                                      placeholderVisible
                                      placement="right"
                                      disabled={checkIfFieldDisabled(
                                        "training_date_finish"
                                      )}
                                      required={
                                        staffOneEditStore.staffTableParams[
                                          "training_date_finish"
                                        ].required
                                      }
                                    />
                                    <ViewField
                                      title="Количество дней обучения"
                                      value={
                                        values["training_date_start"] &&
                                        values["training_date_finish"]
                                          ? calculateDays(
                                              values["training_date_start"],
                                              values["training_date_finish"]
                                            ).toString()
                                          : ""
                                      }
                                      disabled
                                    />
                                  </>
                                ) : (
                                  ""
                                )}
                              </div>
                            );
                          case "training_date_finish": {
                            if (!("training_date_start" in values)) {
                              return (
                                <DatePickerField
                                  key={key}
                                  name={key}
                                  title={colInfo["title"]}
                                  placeholderVisible
                                  isCalendarOpened={openedListName === key}
                                  setIsCalendarOpened={() => {
                                    changeOpenedWindows(key);
                                  }}
                                  placement="right"
                                  disabled={disabled}
                                  required={colParams.required}
                                />
                              );
                            } else return;
                          }
                          case "date_of_employment_of":
                            return (
                              <div
                                className={
                                  "date_of_employment_unof" in values
                                    ? styles.row
                                    : ""
                                }
                                key={key}
                              >
                                <DatePickerField
                                  name={key}
                                  title={colInfo["title"]}
                                  isCalendarOpened={openedListName === key}
                                  setIsCalendarOpened={() => {
                                    changeOpenedWindows(key);
                                  }}
                                  placement="right"
                                  disabled={disabled}
                                  required={colParams.required}
                                />
                                {"date_of_employment_unof" in values ? (
                                  <>
                                    <DatePickerField
                                      name="date_of_employment_unof"
                                      title={
                                        staffOneEditStore.staffTableCols[
                                          "date_of_employment_unof"
                                        ]["title"]
                                      }
                                      isCalendarOpened={
                                        openedListName ===
                                        "date_of_employment_unof"
                                      }
                                      setIsCalendarOpened={() => {
                                        changeOpenedWindows(
                                          "date_of_employment_unof"
                                        );
                                      }}
                                      placement="right"
                                      disabled={disabled}
                                      required={colParams.required}
                                    />
                                  </>
                                ) : (
                                  ""
                                )}
                              </div>
                            );
                          case "date_of_employment_unof": {
                            if (!("date_of_employment_of" in values)) {
                              return (
                                <DatePickerField
                                  name={key}
                                  title={colInfo["title"]}
                                  isCalendarOpened={openedListName === key}
                                  setIsCalendarOpened={() => {
                                    changeOpenedWindows(key);
                                  }}
                                  placement="right"
                                  key={key}
                                  disabled={disabled}
                                  required={colParams.required}
                                />
                              );
                            } else return;
                          }
                          case "trud_dog":
                            return (
                              <div
                                className={
                                  "trud_dog_date" in values ? styles.row : ""
                                }
                                key={key}
                              >
                                <div className={getClassNameForOVColumns(key)}>
                                  {getIconBlock(key)}
                                  <Input
                                    name={key}
                                    label={colInfo["title"]}
                                    onChange={handleChange}
                                    key={key}
                                    disabled={
                                      disabled ||
                                      Boolean(
                                        staffOneEditStore.objectVersionColumns[
                                          key
                                        ]?.["withHistory"]
                                      )
                                    }
                                    required={colParams.required}
                                  />
                                  {getModalWindow(key, colInfo["title"])}
                                </div>
                                {"trud_dog_date" in values ? (
                                  <>
                                    <DatePickerField
                                      name="trud_dog_date"
                                      title={
                                        staffOneEditStore.staffTableCols[
                                          "trud_dog_date"
                                        ]["title"]
                                      }
                                      isCalendarOpened={
                                        openedListName === "trud_dog_date"
                                      }
                                      setIsCalendarOpened={() => {
                                        changeOpenedWindows("trud_dog_date");
                                      }}
                                      placement="right"
                                      disabled={disabled}
                                      required={colParams.required}
                                    />
                                  </>
                                ) : (
                                  ""
                                )}
                              </div>
                            );
                          case "trud_dog_date": {
                            if (!("trud_dog" in values)) {
                              return (
                                <DatePickerField
                                  name={key}
                                  title={colInfo["title"]}
                                  isCalendarOpened={openedListName === key}
                                  setIsCalendarOpened={() => {
                                    changeOpenedWindows(key);
                                  }}
                                  placement="right"
                                  key={key}
                                  disabled={disabled}
                                  required={colParams.required}
                                />
                              );
                            } else return;
                          }

                            case "region":
                              return (
                                <React.Fragment key={key}>
                                  {disabled ? (
                                    <AutoComplete
                                      values={values}
                                      initialValues={initialValues}
                                      name="adress"
                                      label="Автозаполнение адреса"
                                      onChange={(e) => {
                                        handleChange(e);
                                        staffDaDataStore.getAutoData(
                                          "addressFind",
                                          "address",
                                          e.target.value
                                        );
                                      }}
                                      array={staffDaDataStore.autoArrayAddress}
                                      errorMessage={
                                        staffDaDataStore.errorMessageAutoAddress
                                      }
                                      isLoading={isLoadingAutoData}
                                      cleanArray={staffDaDataStore.setAutoArray}
                                      keys={["region", "city"]}
                                    />
                                  ) : (
                                    ""
                                  )}
                                  <div
                                    className={`${styles.wrapper} ${
                                      add1CIcon(key) ? styles.inputWithIcon : ""
                                    }`}
                                  >
                                    {getIconBlock(key)}
                                    <Input
                                      name={key}
                                      label={colInfo["title"]}
                                      onChange={handleChange}
                                      disabled={disabled}
                                      required={colParams.required}
                                    />
                                  </div>
                                  {"city" in values ? (
                                    <div
                                      className={`${styles.wrapper} ${
                                        add1CIcon("city")
                                          ? styles.inputWithIcon
                                          : ""
                                      }`}
                                    >
                                      {getIconBlock("city")}
                                      <Input
                                        name="city"
                                        label={
                                          staffOneEditStore.staffTableCols[
                                            "city"
                                          ].title
                                        }
                                        onChange={handleChange}
                                        disabled={checkIfFieldDisabled("city")}
                                        required={
                                          staffOneEditStore.staffTableParams[
                                            "city"
                                          ].required
                                        }
                                      />
                                    </div>
                                  ) : (
                                    ""
                                  )}
                                </React.Fragment>
                              );
                            case "city": {
                              if (!("region" in values)) {
                                return (
                                  <React.Fragment key={key}>
                                    {disabled ? (
                                      <AutoComplete
                                        values={values}
                                        initialValues={initialValues}
                                        name="adress"
                                        label="Автозаполнение адреса"
                                        onChange={(e) => {
                                          handleChange(e);
                                          staffDaDataStore.getAutoData(
                                            "addressFind",
                                            "address",
                                            e.target.value
                                          );
                                        }}
                                        array={
                                          staffDaDataStore.autoArrayAddress
                                        }
                                        errorMessage={
                                          staffDaDataStore.errorMessageAutoAddress
                                        }
                                        isLoading={isLoadingAutoData}
                                        cleanArray={
                                          staffDaDataStore.setAutoArray
                                        }
                                        keys={["city"]}
                                      />
                                    ) : (
                                      ""
                                    )}
                                    <div
                                      className={`${styles.wrapper} ${
                                        add1CIcon(key)
                                          ? styles.inputWithIcon
                                          : ""
                                      }`}
                                    >
                                      {getIconBlock(key)}
                                      <Input
                                        name={key}
                                        label={colInfo["title"]}
                                        onChange={handleChange}
                                        disabled={disabled}
                                        required={colParams.required}
                                      />
                                    </div>
                                  </React.Fragment>
                                );
                              } else return;
                            }
                            case "surname":
                              return (
                                <React.Fragment key={key}>
                                  {disabled ? (
                                    <AutoComplete
                                      values={values}
                                      initialValues={initialValues}
                                      name="fio"
                                      label="Автозаполнение ФИО"
                                      onChange={(e) => {
                                        handleChange(e);
                                        staffDaDataStore.getAutoData(
                                          "fioFind",
                                          "fio",
                                          e.target.value
                                        );
                                      }}
                                      array={staffDaDataStore.autoArrayFio}
                                      errorMessage={
                                        staffDaDataStore.errorMessageAutoFio
                                      }
                                      isLoading={isLoadingAutoData}
                                      cleanArray={staffDaDataStore.setAutoArray}
                                      keys={["surname", "name", "patronymic"]}
                                    />
                                  ) : (
                                    ""
                                  )}
                                  <div
                                    className={`${styles.wrapper} ${
                                      add1CIcon(key) ? styles.inputWithIcon : ""
                                    }`}
                                  >
                                    {getIconBlock(key)}
                                    <Input
                                      name={key}
                                      label={colInfo["title"]}
                                      onChange={handleChange}
                                      disabled={disabled}
                                      required={colParams.required}
                                    />
                                  </div>
                                  <div className={styles.row}>
                                    {"name" in values ? (
                                      <div
                                        className={`${styles.wrapper} ${
                                          add1CIcon("name")
                                            ? styles.inputWithIcon
                                            : ""
                                        }`}
                                      >
                                        {getIconBlock("name")}
                                        <Input
                                          name="name"
                                          label={
                                            staffOneEditStore.staffTableCols[
                                              "name"
                                            ]["title"]
                                          }
                                          onChange={handleChange}
                                          disabled={checkIfFieldDisabled(
                                            "name"
                                          )}
                                          required={
                                            staffOneEditStore.staffTableParams[
                                              "name"
                                            ].required
                                          }
                                        />
                                      </div>
                                    ) : (
                                      ""
                                    )}
                                    {"patronymic" in values ? (
                                      <div
                                        className={`${styles.wrapper} ${
                                          add1CIcon("patronymic")
                                            ? styles.inputWithIcon
                                            : ""
                                        }`}
                                      >
                                        {getIconBlock("patronymic")}
                                        <Input
                                          name="patronymic"
                                          label={
                                            staffOneEditStore.staffTableCols[
                                              "patronymic"
                                            ]["title"]
                                          }
                                          onChange={handleChange}
                                          disabled={checkIfFieldDisabled(
                                            "patronymic"
                                          )}
                                          required={
                                            staffOneEditStore.staffTableParams[
                                              "patronymic"
                                            ].required
                                          }
                                        />
                                      </div>
                                    ) : (
                                      ""
                                    )}
                                  </div>
                                </React.Fragment>
                              );
                            case "name": {
                              if (!("surname" in values)) {
                                return (
                                  <React.Fragment key={key}>
                                    {disabled ? (
                                      <AutoComplete
                                        values={values}
                                        initialValues={initialValues}
                                        name="fio"
                                        label="Автозаполнение ФИО"
                                        onChange={(e) => {
                                          handleChange(e);
                                          staffDaDataStore.getAutoData(
                                            "fioFind",
                                            "fio",
                                            e.target.value
                                          );
                                        }}
                                        array={staffDaDataStore.autoArrayFio}
                                        errorMessage={
                                          staffDaDataStore.errorMessageAutoFio
                                        }
                                        isLoading={isLoadingAutoData}
                                        cleanArray={
                                          staffDaDataStore.setAutoArray
                                        }
                                        keys={["surname", "name", "patronymic"]}
                                      />
                                    ) : (
                                      ""
                                    )}
                                    <div
                                      className={`${styles.wrapper} ${
                                        add1CIcon(key)
                                          ? styles.inputWithIcon
                                          : ""
                                      }`}
                                    >
                                      {getIconBlock(key)}
                                      <Input
                                        name={key}
                                        label={colInfo["title"]}
                                        onChange={handleChange}
                                        disabled={disabled}
                                        required={colParams.required}
                                      />
                                    </div>
                                    {"patronymic" in values ? (
                                      <div
                                        className={`${styles.wrapper} ${
                                          add1CIcon("patronymic")
                                            ? styles.inputWithIcon
                                            : ""
                                        }`}
                                      >
                                        {getIconBlock("patronymic")}
                                        <Input
                                          name="patronymic"
                                          label={
                                            staffOneEditStore.staffTableCols[
                                              "patronymic"
                                            ]["title"]
                                          }
                                          onChange={handleChange}
                                          disabled={checkIfFieldDisabled(
                                            "patronymic"
                                          )}
                                          required={
                                            staffOneEditStore.staffTableParams[
                                              "patronymic"
                                            ].required
                                          }
                                        />
                                      </div>
                                    ) : (
                                      ""
                                    )}
                                  </React.Fragment>
                                );
                              } else return;
                            }
                            case "patronymic": {
                              if (
                                !("surname" in values) &&
                                !("name" in values)
                              ) {
                                return (
                                  <React.Fragment key={key}>
                                    {disabled ? (
                                      <AutoComplete
                                        values={values}
                                        initialValues={initialValues}
                                        name="fio"
                                        label="Автозаполнение ФИО"
                                        onChange={(e) => {
                                          handleChange(e);
                                          staffDaDataStore.getAutoData(
                                            "fioFind",
                                            "fio",
                                            e.target.value
                                          );
                                        }}
                                        array={staffDaDataStore.autoArrayFio}
                                        errorMessage={
                                          staffDaDataStore.errorMessageAutoFio
                                        }
                                        isLoading={isLoadingAutoData}
                                        cleanArray={
                                          staffDaDataStore.setAutoArray
                                        }
                                        keys={["surname", "name", "patronymic"]}
                                      />
                                    ) : (
                                      ""
                                    )}
                                    <div
                                      className={`${styles.wrapper} ${
                                        add1CIcon(key)
                                          ? styles.inputWithIcon
                                          : ""
                                      }`}
                                    >
                                      {getIconBlock(key)}
                                      <Input
                                        name={key}
                                        label={colInfo["title"]}
                                        onChange={handleChange}
                                        disabled={disabled}
                                        required={colParams.required}
                                      />
                                    </div>
                                  </React.Fragment>
                                );
                              } else return;
                            }
                            case "position": {
                              return (
                                <div
                                  key={key}
                                  className={getClassNameForOVColumns(key)}
                                >
                                  {getIconBlock(key)}
                                  <ViewField
                                    title={colInfo["title"]}
                                    value={
                                      values[key] &&
                                      colParams.variable[
                                        staffOneEditStore.selectedCompanyId[id]
                                      ]?.[values[key]]
                                        ? colParams.variable[
                                            staffOneEditStore.selectedCompanyId[
                                              id
                                            ]
                                          ][values[key]]["title"]
                                        : values[key] &&
                                          staffOneEditStore.selectedCompanyId[
                                            id
                                          ] in
                                            staffOneEditStore
                                              .selectedOneForEdit["company"]
                                        ? "Должность не указана"
                                        : ""
                                    }
                                    required={colParams.required}
                                    className={{
                                      container: classNames("", {
                                        [styles.viewFieldWithIcon]: isOVOrCrm,
                                        [styles.viewFieldWithDoubleIcon]:
                                          isOVAndCrm
                                      })
                                    }}
                                  />
                                  {getModalWindow(key, colInfo["title"])}
                                </div>
                              );
                            }
                            case "living_adress":
                              if (!initialValues["address_match"])
                                initialValues["address_match"] =
                                  values["living_adress"] === values["propisk"]
                                    ? 1
                                    : 0;
                              return (
                                <div className={styles.col} key={key}>
                                  <div className={styles.grayInput}>
                                    <div
                                      className={getClassNameForOVColumns(key)}
                                    >
                                      {getIconBlock(key)}
                                      <Input
                                        name={key}
                                        label={colInfo["title"]}
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        disabled={
                                          values["address_match"] ||
                                          disabled ||
                                          Boolean(
                                            staffOneEditStore
                                              .objectVersionColumns[key]?.[
                                              "withHistory"
                                            ]
                                          )
                                        }
                                        required={colParams.required}
                                      />
                                      {getModalWindow(key, colInfo["title"])}
                                    </div>
                                  </div>
                                  <label className={styles.switch}>
                                    <Switch
                                      name="address_match"
                                      onChange={(e) => {
                                        if (e.target.checked) {
                                          setFieldValue(
                                            "living_adress",
                                            values["propisk"]
                                              ? values["propisk"]
                                              : staffOneEditStore
                                                  .selectedOneForEdit["propisk"]
                                          );
                                        }
                                        setTimeout(() => {
                                          setFieldTouched("address_match");
                                        }, 200);
                                      }}
                                    />
                                    Совпадает с пропиской
                                  </label>
                                </div>
                              );
                            case "propisk":
                              if (!initialValues["address_match"])
                                initialValues["address_match"] =
                                  values["living_adress"] === values["propisk"]
                                    ? "propisk"
                                    : "no_match";
                              return (
                                <div className={styles.inputs} key={key}>
                                  <div
                                    className={getClassNameForOVColumns(key)}
                                  >
                                    {getIconBlock(key)}
                                    <Input
                                      name={key}
                                      onChange={(e) => {
                                        handleChange(e);

                                        if (
                                          values["living_adress"] &&
                                          values["address_match"] === "propisk"
                                        )
                                          setFieldValue(
                                            "living_adress",
                                            e.target.value
                                          );
                                      }}
                                      label={colInfo["title"]}
                                      disabled={
                                        disabled ||
                                        Boolean(
                                          staffOneEditStore
                                            .objectVersionColumns[key]?.[
                                            "withHistory"
                                          ]
                                        )
                                      }
                                      required={colParams.required}
                                    />
                                  </div>
                                  {getModalWindow(key, colInfo["title"])}
                                </div>
                              );
                            case "company":
                              return (
                                <ErrorBoundary
                                  FallbackComponent={ErrorFallback}
                                  key={key}
                                >
                                  <StaffOneEditCompanies
                                    values={
                                      values as {
                                        companies: { [key: string]: ICompany };
                                        company: string[];
                                      }
                                    }
                                    changeOpenedWindows={changeOpenedWindows}
                                    openedListName={openedListName}
                                    disabled={disabled}
                                    required={requiredFields.includes(key)}
                                  />
                                </ErrorBoundary>
                              );
                            case "pasp_n":
                              return (
                                <div
                                  key={key}
                                  className={getClassNameForOVColumns(key)}
                                >
                                  {getIconBlock(key)}
                                  <Input
                                    name={key}
                                    label={colInfo["title"]}
                                    onChange={handleChange}
                                    required={colParams.required}
                                    disabled={
                                      disabled ||
                                      Boolean(
                                        staffOneEditStore.objectVersionColumns[
                                          key
                                        ]?.["withHistory"]
                                      )
                                    }
                                  />
                                  {getModalWindow(key, colInfo["title"])}
                                </div>
                              );
                            case "snils":
                              if (!("hasSnils" in values)) {
                                initialValues.hasSnils = values[key]?.length
                                  ? 0
                                  : 1;
                              }

                              return "hasSnils" in values ? (
                                <div
                                  key={key}
                                  className={styles.snilsContainer}
                                >
                                  {!values["hasSnils"] ? (
                                    <div
                                      className={getClassNameForOVColumns(key)}
                                    >
                                      {getIconBlock(key)}
                                      <NumberFormat
                                        required={requiredFields.includes(key)}
                                        name={key}
                                        value={values[key]}
                                        label={colInfo["title"]}
                                        format="###-###-### ##"
                                        mask="_"
                                        onChange={(
                                          e: React.ChangeEvent<HTMLInputElement>
                                        ) => {
                                          if (
                                            e.target.value?.replace(
                                              /[^0-9]+/g,
                                              ""
                                            ).length === 11 &&
                                            e.target.value !==
                                              initialValues[key]
                                          ) {
                                            staffOneEditStore.checkSnils(
                                              e.target.value.replace(
                                                /[^0-9]+/g,
                                                ""
                                              )
                                            );
                                          } else {
                                            staffOneEditStore.setErrorsMessage(
                                              id,
                                              {}
                                            );
                                          }
                                        }}
                                        onBlur={handleBlur}
                                        customInput={Input}
                                        key={key}
                                        disabled={
                                          disabled ||
                                          Boolean(
                                            staffOneEditStore
                                              .objectVersionColumns[key]?.[
                                              "withHistory"
                                            ]
                                          )
                                        }
                                      />
                                      {getModalWindow(key, colInfo["title"])}
                                    </div>
                                  ) : null}
                                  {Object.values(
                                    staffOneEditStore.errorsMessage[id]
                                  ).length && !errors.snils ? (
                                    <div
                                      className={`${styles.error} ${
                                        !staffOneEditStore.errorsMessage[id]
                                          .result
                                          ? styles.error_withLink
                                          : ""
                                      }`}
                                    >
                                      <ErrorMessage
                                        errors={
                                          staffOneEditStore.errorsMessage[id]
                                            .message
                                        }
                                        errorIcon={
                                          staffOneEditStore.errorsMessage[id]
                                            .result ? (
                                            <IconCircle />
                                          ) : null
                                        }
                                      />
                                      <LoadedComponent
                                        isLoading={
                                          staffOneEditStore.isLoadingField ===
                                          key
                                        }
                                      />
                                    </div>
                                  ) : null}
                                  <label className={styles.switch}>
                                    <Switch
                                      name={"hasSnils"}
                                      onChange={(e) => {
                                        if (e.target.checked) {
                                          staffOneEditStore.setErrorsMessage(
                                            id,
                                            {}
                                          );

                                          requiredFields.includes(key) &&
                                            setRequiredFields(
                                              without(requiredFields, key)
                                            );
                                        } else {
                                          !requiredFields.includes(key) &&
                                            setRequiredFields([
                                              key,
                                              ...requiredFields
                                            ]);

                                          setFieldValue(
                                            key,
                                            initialValues[key]
                                          );
                                        }

                                        setTimeout(() => {
                                          setFieldTouched("hasSnils");
                                        }, 200);
                                      }}
                                    />
                                    Нет СНИЛС
                                  </label>
                                </div>
                              ) : null;
                            case "pasp_code":
                              return (
                                <div
                                  key={key}
                                  className={getClassNameForOVColumns(key)}
                                >
                                  {getIconBlock(key)}
                                  <NumberFormat
                                    name={key}
                                    value={values[key]}
                                    format="###-###"
                                    mask="_"
                                    placeholder="000-000"
                                    label={colInfo["title"]}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    customInput={Input}
                                    disabled={
                                      disabled ||
                                      Boolean(
                                        staffOneEditStore.objectVersionColumns[
                                          key
                                        ]?.["withHistory"]
                                      )
                                    }
                                    required={colParams.required}
                                  />
                                  {getModalWindow(key, colInfo["title"])}
                                </div>
                              );
                            case "fio":
                            case "adress":
                            case "address_match":
                            case "companies":
                            case "contacts":
                            case "black_reason":
                            case "grey_reason":
                              return;
                          }

                          if (
                            colParams.isvariable ||
                            // hardcode для списков, у которых  isvariable: ""
                            ["dismiss_reason"].includes(key)
                          ) {
                            const dict = colParams.variable;

                            if (
                              staffOneEditStore.objectVersionColumns[key]?.[
                                "withHistory"
                              ]
                            ) {
                              return (
                                <div
                                  key={key}
                                  className={getClassNameForOVColumns(key)}
                                >
                                  {getIconBlock(key)}
                                  <ViewField
                                    title={colInfo["title"]}
                                    value={
                                      values[key]
                                        ? (dict[values[key]]["title"] as string)
                                        : ""
                                    }
                                    required={colParams.required}
                                    className={{
                                      container: classNames("", {
                                        [styles.viewFieldWithIcon]: isOVOrCrm,
                                        [styles.viewFieldWithDoubleIcon]:
                                          isOVAndCrm
                                      })
                                    }}
                                  />
                                  {getModalWindow(key, colInfo["title"])}
                                </div>
                              );
                            } else {
                              return (
                                <div
                                  key={key}
                                  id={`StaffOneEditForm_input_${key}`}
                                  onMouseEnter={() =>
                                    colInfo["newname"] === "size"
                                      ? setSelectedWorkerClothes("size")
                                      : null
                                  }
                                  onMouseLeave={() =>
                                    colInfo["newname"] === "size"
                                      ? setSelectedWorkerClothes("none")
                                      : null
                                  }
                                  className={
                                    colInfo["newname"] === "size"
                                      ? styles.inputs_ppe
                                      : ""
                                  }
                                >
                                  <Select
                                    name={key}
                                    title={colInfo["title"]}
                                    isFloating
                                    label={
                                      dict[values[key]]
                                        ? dict[values[key]]["title"]
                                        : values[key]
                                    }
                                    options={
                                      dict as {
                                        [key: string]: {
                                          newname: string;
                                          title: string;
                                        };
                                      }
                                    }
                                    disabled={disabled}
                                    required={colParams.required}
                                  />
                                </div>
                              );
                            }
                          }

                          if (key.includes("comment")) {
                            return (
                              <Textarea
                                key={key}
                                name={key}
                                value={values[key] || ""}
                                placeholder={colInfo["title"]}
                                label={colInfo["title"]}
                                disabled={disabled}
                                required={colParams.required}
                                commentCol={colInfo[key]}
                              />
                            );
                          }

                          if (colInfo)
                            switch (colInfo["type"]) {
                              case "date":
                              case "timestamp":
                                return (
                                  <DatePickerField
                                    key={key}
                                    name={key}
                                    title={colInfo["title"]}
                                    isCalendarOpened={openedListName === key}
                                    setIsCalendarOpened={() => {
                                      changeOpenedWindows(key);
                                    }}
                                    disabled={disabled}
                                    required={colParams.required}
                                  />
                                );
                              case "bool":
                                !initialValues[key]
                                  ? (initialValues[key] = 0)
                                  : "";

                                return (
                                  <React.Fragment key={key}>
                                    <label className={styles.switch}>
                                      <Switch
                                        name={key}
                                        onChange={(e) => {
                                          const changeBoolCols = (
                                            key: string,
                                            checked_key?: string
                                          ) => {
                                            values[key] = initialValues[key];

                                            setFieldValue(
                                              key.replace("reason", "list"),
                                              0
                                            );
                                            setFieldTouched(
                                              key.replace("reason", "list")
                                            );

                                            if (checked_key) {
                                              setFieldValue(
                                                checked_key.replace(
                                                  "reason",
                                                  "list"
                                                ),
                                                1
                                              );
                                              setFieldTouched(
                                                checked_key.replace(
                                                  "reason",
                                                  "list"
                                                )
                                              );
                                            }
                                          };

                                          if (
                                            key === "black_list" ||
                                            key === "grey_list"
                                          ) {
                                            if (
                                              key === "black_list" &&
                                              values["grey_list"]
                                            ) {
                                              return changeBoolCols(
                                                "grey_reason",
                                                key.replace("list", "reason")
                                              );
                                            } else if (
                                              key === "grey_list" &&
                                              values["black_list"]
                                            ) {
                                              return changeBoolCols(
                                                "black_reason",
                                                key.replace("list", "reason")
                                              );
                                            }

                                            if (!e.target.checked) {
                                              changeBoolCols(
                                                key.replace("list", "reason")
                                              );
                                            }
                                          }
                                        }}
                                        disabled={disabled}
                                      />
                                      {colInfo["title"]}
                                    </label>
                                    {(key === "black_list" ||
                                      key === "grey_list") &&
                                    values[key] ? (
                                      <Input
                                        name={key.replace("list", "reason")}
                                        label={colInfo["title"]}
                                        disabled={checkIfFieldDisabled(
                                          key.replace("list", "reason")
                                        )}
                                        required={requiredFields.includes(
                                          key.replace("list", "reason")
                                        )}
                                      />
                                    ) : null}
                                  </React.Fragment>
                                );
                            }

                          if (key === "dismiss_object") {
                            return (
                              <ViewField
                                key={key}
                                title={colInfo["title"]}
                                value={values[key][selectedCompany]}
                                disabled
                              />
                            );
                          } else
                            return (
                              <div
                                id={`StaffOneEditForm_input_${key}`}
                                key={key}
                                onMouseEnter={() =>
                                  colInfo["newname"] === "height" ||
                                  colInfo["newname"] === "shoe_size"
                                    ? setSelectedWorkerClothes(
                                        colInfo["newname"]
                                      )
                                    : null
                                }
                                onMouseLeave={() =>
                                  colInfo["newname"] === "height" ||
                                  colInfo["newname"] === "shoe_size"
                                    ? setSelectedWorkerClothes("none")
                                    : null
                                }
                                className={
                                  colInfo["newname"] === "height" ||
                                  colInfo["newname"] === "shoe_size"
                                    ? styles.inputs_ppe
                                    : ""
                                }
                              >
                                <div className={getClassNameForOVColumns(key)}>
                                  {getIconBlock(key)}
                                  <Input
                                    name={key}
                                    onChange={handleChange}
                                    label={colInfo["title"]}
                                    disabled={
                                      disabled ||
                                      Boolean(
                                        staffOneEditStore.objectVersionColumns[
                                          key
                                        ]?.["withHistory"]
                                      )
                                    }
                                    required={colParams.required}
                                    isNumber={key === "duty_allowance"}
                                  />
                                </div>
                                {getModalWindow(key, colInfo["title"])}
                              </div>
                            );
                        })}
                      </div>

                      {!onlyPhoto && !onlyContacts && cols.length ? (
                        <ButtonsGroupForFormEdit
                          blueBtnTitle="Сохранить"
                          blueBtnDisabled={
                            !isValid ||
                            (!dirty &&
                              staffOneEditStore.errorsMessage?.[id]?.message &&
                              "body" in
                                staffOneEditStore.errorsMessage[id].message) ||
                            (isEqual(values, previousValue) &&
                              (staffOneEditStore.initialCompanyList[
                                id
                              ].includes(
                                staffOneEditStore.selectedCompanyId[id]
                              ) ||
                                !staffOneEditStore.selectedCompanyId[id]))
                          }
                          blueBtnOnClick={() => {
                            handleSubmit();
                            !isEqual(values, previousValue) &&
                              staffOneEditStore.setCurrentCompanyList(id, []);
                          }}
                          grayBtnTitle="Отмена"
                          grayBtnDisabled={
                            !dirty &&
                            isEqual(values, previousValue) &&
                            staffOneEditStore.initialCompanyList[id].includes(
                              staffOneEditStore.selectedCompanyId[id]
                            ) &&
                            (values["company"]
                              ? staffOneEditStore.initialCompanyList[id]
                                  .length ===
                                Object.keys(values["company"]).length
                              : true)
                          }
                          grayBtnOnClick={() => {
                            setRequiredFields(
                              staffOneEditStore.getRequiredCols(cols, data)
                            );
                            staffOneEditStore.setErrorsMessage(id, {});
                            if (!isEqual(values, previousValue)) {
                              staffOneEditStore.setCurrentCompanyList(id, []);
                              setSelectedItem(edit);
                            }
                            staffOneEditStore.initialCompanyList[id].length !==
                              Object.keys(
                                staffOneEditStore.selectedOneForEdit["company"]
                              ).length &&
                              staffOneEditStore.setDeleteNewCompanyData(id);
                            handleReset();
                          }}
                        />
                      ) : (
                        ""
                      )}
                    </Form>
                  </>
                );
              }}
            </Formik>
            {Object.keys(data).filter((dt) =>
              conditionOfPpe.find((el) => el === dt)
            ).length >= 1 && (
              <ErrorBoundary FallbackComponent={ErrorFallback}>
                <StaffOneEditFormWorker
                  selectedOneForEdit={staffOneEditStore.selectedOneForEdit}
                  selectedWorkerClothes={selectedWorkerClothes}
                  showMeasurer={true}
                  titleClothes={staffOneEditStore.staffTableCols["size"].title}
                  titleHeight={staffOneEditStore.staffTableCols["height"].title}
                  titleShoes={
                    staffOneEditStore.staffTableCols["shoe_size"].title
                  }
                />
              </ErrorBoundary>
            )}
          </div>
        </div>
      ) : (
        ""
      )}
    </>
  );
};

export default observer(StaffOneEditForm);
