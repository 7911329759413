import { Formik } from "formik";
import { observer } from "mobx-react-lite";
import { Table } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import Checkbox from "shared/ui/Inputs/Checkbox";
import { useStores } from "stores";
import styles from "./staffRetentionTable.module.scss";
import { useEffect, useState } from "react";
import LoadedComponent from "widgets/LoadedComponent";
import StaffRetentionTableHistoryWindow from "./StaffRetentionTableHistoryWindow";
import ClarificationModal from "shared/ui/Modals/ClarificationModal";
import { ReactComponent as IconAddActive } from "shared/assets/images/mainIcons/iconAdd/iconAddActive.svg";
import { ReactComponent as IconEdit } from "shared/assets/images/mainIcons/iconEdit/iconEditWithCircle.svg";
import { HoldingEduItem } from "stores/StaffModule/types/HoldingEduItem";
import StaffSalaryForm from "features/StaffSalaryForm";
import Tooltip from "shared/ui/Tooltip";
import { browserName } from "react-device-detect";
import StatusIcon from "shared/ui/StatusIcon";
import { ErrorBoundary } from "react-error-boundary";
import ErrorFallback from "widgets/LoadedComponent/Error/ErrorFallback";
import { numberWithSpaces } from "shared/utils/helpers/numberWithSpaces";
import { getTHeadTop } from "shared/utils/helpers/getTHeadTop";
import { getTableMarginTop } from "shared/utils/helpers/getTableMarginTop";
import { classNames } from "shared/utils/helpers/classNames";

const StaffRetentionTable = () => {
  const { staffRetentionListStore, filterStore, menuStore } = useStores();
  const navigate = useNavigate();

  const [isOpenClarificationWindow, setIsOpenClarificationWindow] =
    useState(false);
  const [holdingEduInfo, setHoldingEduInfo] = useState<Partial<HoldingEduItem>>(
    {}
  );
  const [type, setType] = useState<"add" | "edit" | "">("");

  useEffect(() => {
    if (
      menuStore.isScrollBottom &&
      staffRetentionListStore.maxPage >= staffRetentionListStore.page &&
      staffRetentionListStore.page === staffRetentionListStore.prevPage
    ) {
      staffRetentionListStore.setPage(staffRetentionListStore.page + 1);
    }
  }, [menuStore.isScrollBottom]);

  return (
    <>
      <Table
        style={{
          marginTop: getTableMarginTop(
            filterStore.savedFiltersHeight,
            Boolean(
              !staffRetentionListStore.filters[
                staffRetentionListStore.downloadedFilter
              ]?.["general"] || staffRetentionListStore.searchValue
            ),
            filterStore.isOpenAdvancedSearch ||
              filterStore.isOpenedSavedFilterOptions ||
              filterStore.isOpenedColumnsOptions,
            browserName === "Firefox"
          ),
          transition: "0.2s"
        }}
      >
        <thead
          className={styles.thead}
          style={{
            top: staffRetentionListStore.searchValue
              ? getTHeadTop(
                  filterStore.savedFiltersHeight,
                  Boolean(
                    !staffRetentionListStore.filters[
                      staffRetentionListStore.downloadedFilter
                    ]?.["general"] || staffRetentionListStore.searchValue
                  ),
                  filterStore.isOpenedSavedFilters && browserName === "Firefox"
                )
              : "60px"
          }}
        >
          <tr>
            <th className={styles.title}></th>
            {staffRetentionListStore.currentTitles.map((title) => {
              return (
                <th key={title} className={styles.title}>
                  <div className="d-flex align-items-center">
                    <p className="m-0">
                      {
                        staffRetentionListStore.staffRetentionCols[title][
                          "title"
                        ]
                      }
                    </p>
                  </div>
                </th>
              );
            })}
          </tr>
        </thead>
        <tbody className={styles.tableBody}>
          {staffRetentionListStore.holdingEduList.length ? (
            staffRetentionListStore.holdingEduList.map((retention, index) => {
              return (
                <tr
                  key={retention.id}
                  className={`${index % 2 ? styles.greyRow : ""} ${
                    retention.fio ===
                      staffRetentionListStore.selectedStaffName ||
                    retention.id === holdingEduInfo["id"]
                      ? styles.selectedRow
                      : ""
                  }`}
                  id={`StaffRetentionTable_getHistory_${retention.id}`}
                  onClick={() => {
                    staffRetentionListStore.setSelectedStaffName(retention.fio);
                    staffRetentionListStore.getHoldingEduItemHistory(
                      retention.id
                    );
                    staffRetentionListStore.getHoldingEduItemOperations(
                      retention.id
                    );
                  }}
                >
                  <td>
                    <div
                      onClick={(event) => {
                        event.stopPropagation();
                      }}
                      className={`${styles.iconBlock} ${
                        retention.id === holdingEduInfo["id"]
                          ? styles.iconBlock_selectedIcon
                          : ""
                      }`}
                      id={`StaffRetentionTable_action_button_${retention.id}`}
                    >
                      <Tooltip
                        text={
                          Object.keys(retention["withholding"]).length
                            ? "Редактировать вычет"
                            : "Добавить вычет"
                        }
                        placement="right"
                        withoutFlip
                      >
                        <>
                          {!retention["without_edu"] ? (
                            Object.keys(retention["withholding"]).length ? (
                              <IconEdit
                                id={`StaffRetentionTable_edit_${retention.id}`}
                                onClick={() => {
                                  setType("edit");
                                  setHoldingEduInfo(retention);
                                }}
                              />
                            ) : (
                              <IconAddActive
                                id={`StaffRetentionTable_add_${retention.id}`}
                                onClick={() => {
                                  setType("add");
                                  setHoldingEduInfo(retention);
                                }}
                              />
                            )
                          ) : (
                            ""
                          )}
                        </>
                      </Tooltip>
                    </div>
                  </td>
                  {staffRetentionListStore.currentTitles.map((title) => {
                    switch (title) {
                      case "fio":
                        return (
                          <td key={title}>
                            <div className={styles.staffColumn}>
                              <p>{`ТН ${retention["tn"]}`}</p>
                              <Tooltip
                                text="Перейти в профиль сотрудника"
                                placement="right"
                                withoutFlip
                              >
                                <p
                                  className={styles.link}
                                  onClick={(event) => {
                                    event.stopPropagation();
                                    navigate(`../staff/id=${retention["id"]}`);
                                  }}
                                  id={`StaffRetentionTable_navigate_to_id${retention["id"]}`}
                                >
                                  {retention[title]}
                                </p>
                              </Tooltip>
                              <Tooltip
                                text="Смотреть Историю и Операции по вычетам"
                                withoutFlip
                              >
                                <div />
                              </Tooltip>
                            </div>
                          </td>
                        );
                      case "all_operation_sum":
                        return (
                          <td key={title} className={styles.textAlignRight}>
                            <Tooltip
                              text="Смотреть Историю и Операции по вычетам"
                              withoutFlip
                            >
                              <div>
                                {retention["withholding"][title]
                                  ? numberWithSpaces(
                                      Math.abs(retention["withholding"][title])
                                    )
                                  : ""}
                              </div>
                            </Tooltip>
                          </td>
                        );
                      case "remainder_operationless":
                        return (
                          <td key={title} className={styles.textAlignRight}>
                            <Tooltip
                              text="Смотреть Историю и Операции по вычетам"
                              withoutFlip
                            >
                              <div className={styles.tooltip}>
                                {title in retention["withholding"]
                                  ? numberWithSpaces(
                                      retention["withholding"][title]
                                    )
                                  : ""}
                              </div>
                            </Tooltip>
                          </td>
                        );
                      case "closed_ws_num":
                        return (
                          <td key={title}>
                            <Tooltip
                              text="Смотреть Историю и Операции по вычетам"
                              withoutFlip
                            >
                              <div className={styles.tooltip}>
                                {title in retention["withholding"]
                                  ? retention["withholding"][title] || 0
                                  : ""}
                              </div>
                            </Tooltip>
                          </td>
                        );
                      case "status":
                        return (
                          <td key={title}>
                            <Tooltip
                              text="Смотреть Историю и Операции по вычетам"
                              withoutFlip
                            >
                              <div className={styles.statusColumn}>
                                <StatusIcon
                                  icon={
                                    staffRetentionListStore.statusList[
                                      retention["withholding"]?.status
                                    ]?.["icon"]
                                  }
                                  color={
                                    staffRetentionListStore.statusList[
                                      retention["withholding"]?.status
                                    ]?.["color_api"]
                                  }
                                />
                                <p>
                                  {staffRetentionListStore.statusList[
                                    retention["withholding"]?.status
                                  ]?.["show_title_api"]
                                    ? staffRetentionListStore.statusList[
                                        retention["withholding"]?.status
                                      ]?.title
                                    : ""}
                                </p>
                              </div>
                            </Tooltip>
                          </td>
                        );

                      case "without_edu":
                        return (
                          <td key={title}>
                            <Formik
                              initialValues={retention}
                              enableReinitialize
                              onSubmit={() => {
                                return;
                              }}
                            >
                              {({ values }) => {
                                return (
                                  <>
                                    <Tooltip
                                      text={
                                        !retention["without_edu"]
                                          ? `Проставить свойство "Без УЦ"`
                                          : `Снять свойство "Без УЦ"`
                                      }
                                      placement="left"
                                      withoutFlip
                                    >
                                      <div
                                        className={`${styles.checkboxColumn} ${styles.tooltip}`}
                                        onClick={(event) => {
                                          event.stopPropagation();
                                        }}
                                        id={`StaffRetentionTable_without_edu_${retention.id}`}
                                      >
                                        <Checkbox
                                          name={title}
                                          withoutSetFieldValue
                                          disabled={Boolean(
                                            Object.keys(
                                              retention["withholding"]
                                            ).length
                                          )}
                                          onChange={() => {
                                            setIsOpenClarificationWindow(true);
                                            setHoldingEduInfo(values);
                                          }}
                                        />
                                      </div>
                                    </Tooltip>
                                  </>
                                );
                              }}
                            </Formik>
                          </td>
                        );
                      default:
                        return (
                          <td key={title}>
                            <Tooltip
                              text="Смотреть Историю и Операции по вычетам"
                              withoutFlip
                            >
                              <div
                                className={classNames(styles.tooltip, {
                                  [styles.comment]: title.includes("comment")
                                })}
                              >
                                {retention["withholding"]?.[title] || ""}
                              </div>
                            </Tooltip>
                          </td>
                        );
                    }
                  })}
                </tr>
              );
            })
          ) : (
            <tr className={styles.error}>
              <td colSpan={staffRetentionListStore.currentTitles.length}>
                {staffRetentionListStore.errorMessage
                  ? staffRetentionListStore.errorMessage
                  : ""}
              </td>
            </tr>
          )}
        </tbody>
      </Table>
      {staffRetentionListStore.page !== 1 ? (
        <div className={styles.firstCol}>
          {staffRetentionListStore.isLoading ? (
            <p className={styles.loading}>Пожалуйста, подождите</p>
          ) : (
            ""
          )}
        </div>
      ) : (
        <LoadedComponent isLoading={staffRetentionListStore.isLoading} />
      )}
      {staffRetentionListStore.selectedStaffName ? (
        <ErrorBoundary FallbackComponent={ErrorFallback}>
          <StaffRetentionTableHistoryWindow />
        </ErrorBoundary>
      ) : (
        ""
      )}
      <StaffSalaryForm
        title={
          type === "add" ? "Добавление вычета УЦ" : "Редактирование вычета УЦ"
        }
        nameOneOfStaff={holdingEduInfo["fio"]}
        isOpenModal={Boolean(type)}
        setIsOpenModal={() => {
          setType("");
          setHoldingEduInfo({});
        }}
        clearErrorsMessage={() => staffRetentionListStore.clearErrorsMessage()}
        type={type}
        fields={
          type === "add"
            ? ["date_start", "money", "ws_num", "comment"]
            : ["status", "date_start", "money", "ws_num", "comment"]
        }
        dataForEdit={holdingEduInfo["withholding"]}
        disabledFields={["money", "ws_num"]}
        staffID={holdingEduInfo["id"]}
        saveBtnOnClickFunc={
          type === "add"
            ? staffRetentionListStore.addHoldingEdu
            : staffRetentionListStore.editWEduRecord
        }
        money={staffRetentionListStore.money}
        ws_num={staffRetentionListStore.ws_num}
        errorsMessage={staffRetentionListStore.errorsMessage}
        isHoldingEdu
      />
      {isOpenClarificationWindow ? (
        <ClarificationModal
          title={`${
            !holdingEduInfo["without_edu"]
              ? "Проставить сотруднику"
              : "Снять у сотрудника"
          } признак "Без УЦ"?`}
          show={isOpenClarificationWindow}
          btnBlueTitle="Отмена"
          btnWithCrossTitle={
            !holdingEduInfo["without_edu"] ? "Проставить" : "Снять"
          }
          btnWithCrossOnClick={() => {
            staffRetentionListStore.setWithoutEdu(
              holdingEduInfo["id"],
              !holdingEduInfo["without_edu"] ? "set" : "unset"
            );
            setIsOpenClarificationWindow(false);
            setHoldingEduInfo({});
          }}
          btnBlueOnClick={() => {
            setIsOpenClarificationWindow(false);
            setHoldingEduInfo({});
          }}
          onHide={() => {
            setIsOpenClarificationWindow(false);
            setHoldingEduInfo({});
          }}
          withoutExitBtn
        />
      ) : (
        ""
      )}
    </>
  );
};

export default observer(StaffRetentionTable);
