import styles from "./formWindow.module.scss";
import { useEffect, useRef, useState } from "react";

import { useFormikContext } from "formik";

import FormWindowHeader from "./FormWindowHeader/index";
import ClarificationModal from "shared/ui/Modals/ClarificationModal";
import ButtonsGroupForSettings from "shared/ui/ButtonsGroup/ButtonsGroupForSettings";

import ErrorMessage from "shared/ui/ErrorMessage";
import Scrollbars from "react-custom-scrollbars-2";
import { useOnClickOutside } from "shared/utils/hooks/useOnClickOutside";
import LoadedComponent from "widgets/LoadedComponent";
import { maxHeight } from "shared/utils/constants/maxHeight";
import { FormWindowProps } from "./types";

const FormWindow = ({
  title,
  children,
  additionalChildren,
  setOpenWindow,

  subtitle,
  isScroll,

  optionalCloseFunc,

  saveBtnTitle,
  saveBtnOnClickFunc,
  saveBtnDisabledValue,

  addBtnTitle,
  addBtnOnClickFunc,
  addBtnImg,
  addBtnDisabledValue,

  cancelBtnTitle,
  cancelBtnOnClick,
  cancelBtnDisabled,
  cancelBtnImg,

  errors,
  isLoadingForModal,
  isNotHaveButtons,
  ignorOnClickOutside,
  fromOverlayWindow,

  hasBackBtn,
  withoutSendData
}: FormWindowProps) => {
  const [openModal, setOpenModal] = useState(false);
  const [isCloseByBackBtn, setIsCloseByBackBtn] = useState(false);
  const { dirty, handleSubmit, handleReset } =
    !withoutSendData && useFormikContext();
  const ref = useRef();

  const setCloseWindow = () => {
    setOpenWindow(false);
    optionalCloseFunc && optionalCloseFunc();
  };

  const closeWindow = () => {
    if (!withoutSendData) {
      if (dirty) {
        setOpenModal(true);
      } else {
        setCloseWindow();
        handleReset();
      }
    } else {
      setCloseWindow();
    }
  };

  useOnClickOutside({
    ref,
    handler: (event) => {
      if (!ignorOnClickOutside) {
        // условие ниже не позволяет закрывать окно при клике на выпадающий список
        if (
          event.target?.["dataset"]["list"] === "true" ||
          event.target?.["dataset"]["type"] === "list-search" ||
          (typeof event.target?.["childNodes"]?.[0] === "string" &&
            event.target?.["childNodes"]?.[0]?.includes("itemsScrollBoard")) ||
          event.target?.["parentNode"]?.["dataset"]["list"] === "true"
        )
          return;

        if (
          event.target?.["firstChild"]?.["className"]?.length ||
          fromOverlayWindow
        ) {
          (event.target["firstChild"]?.["className"]?.includes("window") ||
            fromOverlayWindow) &&
            closeWindow();
        }
      }
    }
  });

  const onKeyDown = ({ key }: KeyboardEvent) => {
    if (key === "Escape") {
      closeWindow();
    }
  };

  useEffect(() => {
    document.addEventListener("keydown", onKeyDown);
    return () => document.removeEventListener("keydown", onKeyDown);
  });

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  const handleCrossClick = () => {
    setOpenModal(false);
    !isCloseByBackBtn
      ? setOpenWindow(false)
      : addBtnOnClickFunc && addBtnOnClickFunc();
    addBtnTitle === "Отмена" && optionalCloseFunc && optionalCloseFunc();

    handleReset();
    setIsCloseByBackBtn(false);
  };

  const handleAddButtonOnClick = () => {
    if (dirty) {
      setOpenModal(true);
      hasBackBtn && setIsCloseByBackBtn(true);
    } else {
      !withoutSendData && handleReset();

      if (addBtnOnClickFunc && hasBackBtn) return addBtnOnClickFunc();

      setCloseWindow();
    }
  };

  const renderErrors = () => {
    if (errors && Object.values(errors).length) {
      return (
        <div className={styles.errors}>
          {Object.values(errors).map((error) => {
            return <ErrorMessage key={error.head} errors={error} />;
          })}
        </div>
      );
    }
  };

  const renderContent = () => {
    return (
      <>
        {children}
        {renderErrors()}
        {isNotHaveButtons ? null : (
          <div className={styles.buttons}>
            <ButtonsGroupForSettings
              saveBtnTitle={saveBtnTitle}
              saveBtnDisabled={saveBtnDisabledValue}
              saveBtnOnClick={saveBtnOnClickFunc || handleSubmit}
              addBtnTitle={addBtnTitle}
              addBtnOnClick={handleAddButtonOnClick}
              addBtnDisabled={addBtnDisabledValue}
              addBtnImg={addBtnImg}
              cancelBtnTitle={cancelBtnTitle}
              cancelBtnDisabled={cancelBtnDisabled}
              cancelBtnOnClick={cancelBtnOnClick}
              cancelBtnImg={cancelBtnImg}
            />
          </div>
        )}
        {additionalChildren ? additionalChildren : null}
      </>
    );
  };

  return (
    <div className={styles.window} ref={ref}>
      <FormWindowHeader
        title={title}
        subtitle={subtitle}
        closeWindowFunc={!ignorOnClickOutside ? closeWindow : optionalCloseFunc}
      />
      {isScroll ? (
        <Scrollbars
          width="100%"
          autoHeight
          autoHeightMax={`calc(${maxHeight} - 160px)`}
          autoHide
          className={styles.body}
          autoHideTimeout={1000}
          autoHideDuration={200}
        >
          {renderContent()}
        </Scrollbars>
      ) : (
        <div className={styles.body}>{renderContent()}</div>
      )}
      <LoadedComponent isLoading={isLoadingForModal} />
      <ClarificationModal
        show={openModal}
        onHide={handleCloseModal}
        title="Закрыть окно без сохранения данных?"
        btnWithCrossTitle="Закрыть"
        btnWithCrossOnClick={handleCrossClick}
        btnBlueOnClick={handleCloseModal}
        btnBlueTitle="Отмена"
        btnWithCrossBtnImg
      />
    </div>
  );
};

export default FormWindow;
